<template>
    <button ref="buttonRef" @click="doMarkAsDeleted" :class="className" :title="$t('Delete record')" :disabled="isSaving || confirmShown">
        <div class="spinner-border spinner-border-sm me-1" role="status" v-if="props.dataObject && props.dataObject.isSaving">
            <span class="sr-only"></span>
        </div>
        <slot>
        </slot>
    </button>
</template>

<script setup>
import { useAttrs, computed, ref } from 'vue';
import { $t } from 'o365-utils';
import { getDataObjectById } from 'o365-dataobject' 

const props = defineProps({
        dataObject: {
            type: Object,
            default:undefined
        },
        row: {
            type: Object,
            default:undefined
        },
        confirm: null,
    }
);

const attrs = useAttrs();
const buttonRef = ref(null);

function o365_confirm(...args) {
    return import('o365-vue-services').then(services => services.confirm(...args));
}

const className = computed(() => {
    return attrs.class ? '' : 'btn btn-sm btn-link';
});

const _dataObject = computed(() => {
    if (props.dataObject) {
        return props.dataObject
    } else {
        const dataObjectId = props.row.dataObjectId;
        const appId = props.row.appId;
        return getDataObjectById(dataObjectId, appId);
    }
});

const _row = computed(() => {
    return props.row ?? _dataObject.value.current;
});

const isSaving = computed(() => {
    return _row.value.isSaving ?? false;
});

const confirmShown = computed(() => {
    return _row.value.o_DeleteConfirm;
});

const markAsDeleted = () => {
    if(_dataObject.value.fields.Deleted){
        _row.value.Deleted = new Date();
        _dataObject.value.save().then(() => {
            _dataObject.value.load();
        });
    }
    

}

function doMarkAsDeleted() {
    let vConfirm = _dataObject.value.deleteConfirm;
    if (typeof props.confirm !== 'undefined') { 
        if (typeof props.confirm === 'string') {
            vConfirm = props.confirm !== 'false';
        } else {
            vConfirm = props.confirm;
        }
    }
    if (vConfirm) {
        const isInModal = !!buttonRef.value?.closest('.modal');
        _row.value.o_DeleteConfirm = true;
        o365_confirm({
            message: $t('Are you sure you want to delete?'),
            title: $t('Delete confirm'),
            btnTextOk: $t('Delete'),
            btnClassOk: 'btn-primary',
            btnClassCancel: 'btn-outline-primary',
            zIndex: isInModal ? 1060 : null
        }).then(() => {
            markAsDeleted();
        }).catch(_ => {}).finally(() => {
            delete _row.value.o_DeleteConfirm;
        });
    } else {
        markAsDeleted();
    }
}

</script>

